import React from 'react';
//import logo from './logo.svg';
import './App.css';
//--
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
// eslint-disable-next-line
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

Amplify.configure(awsconfig);
//---
const gest_vals = [23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
const ster_vals = [ {key:'Yes', val:1}, {key:'No', val:0}];
const gender_vals = [ {key:'Male', val:1}, {key:'Female', val:0}];
const mode_vals = [ {key:'C-Section', val:1}, {key:'NSVD', val:0}];
const apgar_vals = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const loc_vals = [ {key:'Outborn', val:1}, {key:'Inborn', val:0}];

class SIVHInput extends React.Component {
  render() {
    // eslint-disable-next-line
    var submit_button = '';
    submit_button = <input type="submit" value="Calculate SIVH Risk value" className="subButton"/>;

    const gest_options = gest_vals.map( (ga) =>  <option value={ga}>{ga} weeks</option>);
    const ster_options = ster_vals.map( (st) => <option value={st.val}>{st.key}</option>);
    const gender_options = gender_vals.map( (gn) => <option value={gn.val}>{gn.key}</option>);
    const mode_options = mode_vals.map( (md) => <option value={md.val}>{md.key}</option>);
    const apgar_options = apgar_vals.map( (ap) =>  <option value={ap}>{ap}</option>);
    const loc_options = loc_vals.map( (lc) => <option value={lc.val}>{lc.key}</option>);

    return (
      <form onSubmit={this.props.handleSubmit}>
        <table>
        <tbody>
          <tr>
            <td><b>Gestational age (weeks):</b></td> 
            <td><div className="sivh-input">
              <select name="gest" value={this.props.gest} onChange={this.props.handleInputChange}> {gest_options} </select>
            </div></td>
            <td><b>Birth weight (grams):</b></td>
            <td><div className="sivh-input">
              <input name="b_weight" type="number" value={this.props.b_weight} onChange={this.props.handleInputChange}/>
            </div></td>
          </tr>
          <tr>
            <td><b>Any Antenatal Steroids:</b></td>
            <td><div className="sivh-input">
              <select name="ster" value={this.props.ster} onChange={this.props.handleInputChange}> {ster_options} </select>
            </div></td>
            <td><b>Gender:</b></td>
            <td><div className="sivh-input">
              <select name="gender" value={this.props.gender} onChange={this.props.handleInputChange}> {gender_options} </select>
            </div></td>
          </tr>
          <tr>
            <td><b>Mode of delivery:</b></td>
            <td><div className="sivh-input">
              <select name="mode" value={this.props.mode} onChange={this.props.handleInputChange}> {mode_options} </select>
            </div></td>
            <td><b>5 minutes APGAR:</b></td>
            <td><div className="sivh-input">
              <select name="apgar" value={this.props.apgar} onChange={this.props.handleInputChange}> {apgar_options} </select>
            </div></td>
          </tr>
          <tr>
            <td><b>Location of delivery:</b></td>
            <td><div className="sivh-input">
              <select name="loc" value={this.props.loc} onChange={this.props.handleInputChange}> {loc_options} </select>
            </div></td>
          </tr>
          <tr>
            <td colSpan='4' align='center' className='sivh-risk'> {this.props.sivh_risk} </td>
          </tr>
        </tbody>
        </table>
      </form>
    );
  }
}    


class SIVHCompute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gest: 24,
      b_weight: '',
      ster: 0,
      gender: 0,
      mode: 0,
      apgar: 3,
      loc: 0,
      sivh_risk: 'Please provide all input values.',
      all_valid_input: false,
      submit_clicked: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.allInputSupplied = this.allInputSupplied.bind(this);
    this.sivh_risk_calc = this.sivh_risk_calc.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name; 
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    const value = target.value;
    // Checking if value is a number (in case of 0, add 1 and then check so it returns no-zero value)
    if (Number(value+1) || value === '') { 
      this.setState({[name]: value}, () => this.allInputSupplied() );
     //this.setState({[name]: value});
   }
  }

  handleSubmit(event) {
    alert('Submitting: ');
    event.preventDefault();
    this.setState({
      submit_clicked: true
    });
  }

  allInputSupplied() {
    if (Number(this.state.gest) >= 23 &&  Number(this.state.gest) <= 34 &&
        Number(this.state.b_weight) > 0 &&
        Number(this.state.apgar) >= 0 && Number(this.state.apgar) <= 10
        ) {
          this.setState({all_valid_input: true});
          this.setState({sivh_risk: this.sivh_risk_calc()});

    } else {
          this.setState({all_valid_input: false});
          this.setState({sivh_risk: 'Please provide all input values.'});
    }
  }

  sivh_risk_calc() {
    var gest_val = parseInt(this.state.gest);
    var b_weight_val = parseInt(this.state.b_weight);
    var ster_val = parseInt(this.state.ster);
    var gender_val = parseInt(this.state.gender);
    var mode_val = parseInt(this.state.mode);
    var apgar_val = parseInt(this.state.apgar);
    var loc_val = parseInt(this.state.loc);

    var xbhat_val=11.74+(0.5247*loc_val)-(0.4661*gest_val)-(0.0002*b_weight_val)-(0.0773*gender_val)-
      (0.7775*ster_val)-(0.0579*mode_val)-(0.1355*apgar_val);
    xbhat_val=xbhat_val.toFixed(4);
    var sivh_display_val = "";
    if(isNaN(xbhat_val)) {
      sivh_display_val ="Invalid Values, Try Again";
    }
    var sivh_risk=(Math.exp(xbhat_val)/(1+Math.exp(xbhat_val)));
    sivh_risk=sivh_risk.toFixed(4);
    sivh_display_val='Risk: '+(sivh_risk*100).toFixed(2)+'%';
    return sivh_display_val;
  }

  render() {
    return (
      <div>
       <h2> Severe Intraventricular Hemorrhage (SIVH) Risk Calculation </h2>
       <a href='http://www.nature.com/srep/2013/130902/srep02539/pdf/srep02539.pdf' target='_blank'  rel="noopener noreferrer">
        Link: A Predictive Model for SIVH risk in Preterm Infants and Targeted Indomethacin Therapy for Prevention</a>
        <fieldset>
         <legend> Enter your data.</legend>
          <SIVHInput
            gest={this.state.gest}
            b_weight={this.state.b_weight}
            ster={this.state.ster}
            gender={this.state.gender}
            mode={this.state.mode}
            apgar={this.state.apgar}
            loc={this.state.loc}
            sivh_risk={this.state.sivh_risk}
            handleInputChange={this.handleInputChange}
            handleSubmit={this.handleSubmit}
          />
        </fieldset>
      </div>
    )
  }  
}

export default SIVHCompute;
